// socket.js
import { io } from "socket.io-client";

const socket = io("https://api.omicron.org.in", {
  transports: ["websocket"], // Use only websocket transport
  reconnectionAttempts: 5,   // Limit reconnection attempts
  reconnectionDelay: 1000,   // Set a delay for reconnection
});

// Handle connection events
socket.on("connect", () => {
  console.log("Successfully connected to the server.");
});

socket.on("connect_error", (err) => {
  console.error("Connection Error:", err.message);
});

// Listen for updates from the server
socket.on("update_data", (data) => {
  console.log("Data updated:", data);
});

export default socket;
