import React from 'react';

function PersonalDetails({ isEditMode }) {
  return (
    <div>
      {/* .....................Profile Name section Starts......................... */}
      <div className="personal-details">
        <span>Personal Details</span>
        <hr />
        <div className="profileName">
          <span>Employee Full Name</span>
          <div className="dataInput">
            <div className="inputarea">
              <input type="text" id="first-name" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
              <label htmlFor="first-name" className="input-title">First Name</label>
            </div>
            <div className="inputarea">
              <input type="text" id="middle-name" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
              <label htmlFor="middle-name" className="input-title">Middle Name</label>
            </div>
            <div className="inputarea">
              <input type="text" id="last-name" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
              <label htmlFor="last-name" className="input-title">Last Name</label>
            </div>
          </div>
        </div>
        {/* .....................Profile Name section Ends......................... */}

        <hr />
        {/* .............................Other Detail section starts......................... */}
        <div className="Other-details">
          <div className="dataInput">
            <div className="inputarea">
              <input type="text" id="nationality-1" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
              <label htmlFor="nationality-1" className="input-title">Nationality</label>
            </div>
            <div className="inputarea">
              <input type="text" id="marital-status" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
              <label htmlFor="marital-status" className="input-title">Marital Status</label>
            </div>
          </div>
          <div className="dataInput">
            <div className="inputarea">
              {isEditMode ? (
                <input type="date" id="dateofJoin" className="dateofjoin" name="DateofJoin" />
              ) : (
                <input type="text" id="dateofJoin" readOnly className="relation-with-contact" name="RelationwithContact" />
              )}
              <label htmlFor="dateofJoin" className="input-title">Date OF Joining</label>
            </div>
            <div className="gender">
              <label className="input-title-gender">Gender</label>
              <div className='gender-selection'>
                <div className="options">
                  <input type='radio' id='male' disabled={!isEditMode} name='gender' />
                  <label htmlFor="male">Male</label>
                </div>
                <div className="options">
                  <input type='radio' id='female' disabled={!isEditMode} name='gender' />
                  <label htmlFor="female">Female</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* .............................Other Detail section ends......................... */}
        <hr />
        <div className="dataInput">
          <div className="inputarea">
            <input type="text" id="adhar-number" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
            <label htmlFor="adhar-number" className="input-title">Adhar Number</label>
          </div>
          <div className="inputarea">
            <input type="text" id="pan-number" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
            <label htmlFor="pan-number" className="input-title">Pan Number</label>
          </div>
        
          <div className="inputarea">
            <input type="text" id="blood-group" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
            <label htmlFor="blood-group" className="input-title">Blood Group</label>
          </div>
          <div className="inputarea">
            {isEditMode ? (
              <input type="date" id="dateofBirth" className="relation-with-contact" name="RelationwithContact" />
            ) : (
              <input type="text" id="dateofBirth" readOnly className="relation-with-contact" name="RelationwithContact" />
            )}
            <label htmlFor="dateofBirth" className="input-title">Date Of Birth</label>
          </div>
          <div className="inputarea">
            <input type="text" id="blood-group" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
            <label htmlFor="blood-group" className="input-title">PF Number</label>
          </div> <div className="inputarea">
            <input type="text" id="blood-group" readOnly={!isEditMode} className="relation-with-contact" name="RelationwithContact" />
            <label htmlFor="blood-group" className="input-title">Policy Number</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
