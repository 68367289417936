import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Sidenav from "./component/Sidenav/Sidenav.js";
import EmployeesDB from "./pages/Employees/EmployeesDB.js";
import AddEmployee from "./component/AddEmployee/AddEmployee.js";
import { initialState, reducer } from './Reducer/userReducer.js';
import ResetPassword from "./component/Login/ResetPassword.js";
import Login from "./component/Login/Login.js";
import RequestPassword from "./component/Login/RequestPassword.js";
import EmployeeProfile from "./component/EmployeeProfile/EmployeeProfile.js";
import PersonalDetails from "./component/EmployeeProfile/PersonalDetails.js";
import ProfileDropdown from "./component/ProfileDropdown/ProfileDropdown.js";
import Notifications from "./component/Notification/Notifications.js";
import DashBoard from "./pages/DashBoard/DashBoard.js";
import Admin from "./pages/Admin/Admin.js";
import EditUser from "./component/EditUser/EditUser.js";
import ProjectTracker from "./pages/ProjectTracker/ProjectTracker.js";
import Attendance from "./pages/Attendance/Attendance.js";
import ProjectTable from "./pages/ProjectTracker/Showprojects.js";
import EmployeepermissionList from "./pages/Employees/EmployeepermissionList.js";
import Reports from "./component/Reports/Reports.js";
import "./App.css";
import Organization from "./pages/organization/organization.js";
import ArchiveProject from "./pages/ArchiveProjects/ArchivedProjects.js";

export const UserContext = createContext();

const fetchUserDetails = async (dispatch) => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("jwt");

  // Prevent right-click context menu
document.addEventListener('contextmenu', (event) => {
  event.preventDefault();
});

// Prevent keyboard shortcuts for saving the page
document.addEventListener('keydown', (event) => {
  if (event.ctrlKey && (event.key === 's' || event.key === 'S')) {
    event.preventDefault();
  }
});


  if (user && token) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}user-details`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        dispatch({ type: "USER", payload: data });
      } else if (response.status === 401) {
        // Handle unauthorized access
        localStorage.clear();
        window.location.href = '/login';
      } else {
        console.error('Failed to fetch user details:', response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }
};

const Routing = ({ isCollapsed, toggleNav, setIsCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(UserContext);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("jwt");

    if (user && token) {
      try {
        dispatch({ type: "USER", payload: JSON.parse(user) });
        fetchUserDetails(dispatch);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    } else if (!["/login", "/request-reset", location.pathname.match(/^\/reset-password\/[^/]+$/)].some(path => location.pathname === path)) {
      navigate('/login');
    }
  }, [dispatch, navigate, location.pathname]);

  const isLoggedIn = Boolean(state.user);

  useEffect(() => {
    const preventDefaultActions = (event) => {
      if (event.type === 'contextmenu' || (event.key === 'p' && (event.ctrlKey || event.metaKey))) {
        event.preventDefault();
      }
    };

    document.addEventListener('contextmenu', preventDefaultActions);
    document.addEventListener('keydown', preventDefaultActions);

    const overlay = document.createElement('div');
    overlay.className = 'screenshot-overlay';
    document.body.appendChild(overlay);

    return () => {
      document.removeEventListener('contextmenu', preventDefaultActions);
      document.removeEventListener('keydown', preventDefaultActions);
      document.body.removeChild(overlay);
    };
  }, []);

  return (
    <>
      <header className="sticky-header">
        {isLoggedIn && <ProfileDropdown />}
      </header>
      <div className="home-wrap">
        {isLoggedIn && !["/login", "/request-reset", location.pathname.match(/^\/reset-password\/[^/]+$/)].includes(location.pathname) && (
          <div
          className={`left ${isCollapsed ? "collapsed" : ""}`}
          onMouseEnter={() => setIsCollapsed(false)} // Expand on hover
          onMouseLeave={() => setIsCollapsed(true)} // Collapse on mouse leave
        >
          <Sidenav userId={state.user._id} userDepartment={state.user.Department} userRole={state.user.Role} isCollapsed={isCollapsed} toggleNav={toggleNav} />
          <button className="toggle-btn" onClick={toggleNav}>
            <i className={`fa-solid fa-${isCollapsed ? 'xmark' : 'bars'}`}></i>
          </button>
        </div>
        
        )}
        <div className={`right ${!isLoggedIn ? 'full-width' : ''} ${isCollapsed ? "collapsed" : ""}`}>
          <Routes>
            {state.user?.Role === 'administrator' && (
              <>
                <Route path="/admin" element={<Admin />} />
                <Route path="/addemployee" element={<AddEmployee />} />
              </>
            )}
            <Route path="/edit-user/:id" element={<EditUser />} />
            <Route path="/employee-dashboard" element={<EmployeesDB />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/add-project/:id" element={<ProjectTracker />} />
            <Route path="/project-table/:id" element={<ProjectTable />} />
            <Route path="/request-reset" element={<RequestPassword />} />
            <Route path="/employee-profile/:id" element={<EmployeeProfile />} />
            <Route path="/assign-user" element={<EmployeepermissionList />} />
            <Route path="/project-users/:projectId" element={<ProjectTable />} />
            <Route path="/" element={<DashBoard />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/reports/:id" element={<Reports />} />
            <Route path="/personal-details" element={<PersonalDetails />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path='/archive-projects' element={<ArchiveProject />} />
            <Route path="/organization" element={<Organization/>}/>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

const NotFound = () => <div>404 - Page Not Found</div>;

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleNav = () => setIsCollapsed(!isCollapsed);

  return (
    <div className="App">
      <UserContext.Provider value={{ state, dispatch }}>
        <Router>
          <Routing
            isCollapsed={isCollapsed}
            toggleNav={toggleNav}
            setIsCollapsed={setIsCollapsed} // Pass setIsCollapsed
          />
        </Router>
      </UserContext.Provider>
    </div>
  );
}


export default App;
