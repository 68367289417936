import React from "react";
import Logo from "../../Assets/Images/logo.png"; // Update the path to your logo
import "./sidenav.css"; // Import CSS for styling
import { NavLink } from "react-router-dom";

function Sidenav({ userId, userRole, isCollapsed, userDepartment, toggleNav }) {
  const getNavLinkClassName = ({ isActive }) => 
    `menu-item ${isActive ? 'active' : ''}`;

  return (
    <>
      <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <hr style={{margin:'30px 0'}}/>
        <div className={`main-menu ${isCollapsed ? "collapsed" : ""}`}>
          <ul>
            {userRole === "administrator" && (
              <li>
                <NavLink to="/admin" className={getNavLinkClassName}>
                  <i className="fa-solid fa-user-gear"></i>
                  {!isCollapsed && <span>Admin</span>}
                </NavLink>
                <div className="arrow" onClick={toggleNav}></div>
              </li>
            )}
            {userRole === "administrator"  && (
              <li>
                <NavLink to="/employee-dashboard" className={getNavLinkClassName}>
                  <i className="fa-regular fa-address-card"></i>
                  {!isCollapsed && <span>EIM</span>}
                </NavLink>
                <div className="arrow" onClick={toggleNav}></div>
              </li>
            )}
            <li>
              <NavLink to="/" className={getNavLinkClassName}>
                <i className="fa-solid fa-house"></i>
                {!isCollapsed && <span>Dashboard</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
            <li>
              <NavLink to={`/project-table/${userId}`} className={getNavLinkClassName}>
                <i className="fa-solid fa-table-list"></i>
                {!isCollapsed && <span>Project Tracker</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
            <li>
              <NavLink to={`/attendance/`} className={getNavLinkClassName}>
                <i className="fa-solid fa-clipboard-user"></i>
                {!isCollapsed && <span>Attendance</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
            <li>
              <NavLink to={`/organization`} className={getNavLinkClassName}>
                <i className="fa-solid fa-users"></i>
                {!isCollapsed && <span>Organization</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
            <li>
              <NavLink to={`/notifications`} className={getNavLinkClassName}>
                <i className="fa-solid fa-bell"></i>
                {!isCollapsed && <span>Notifications</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
