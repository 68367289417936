import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'; // Import axios
import './styles/contactDetails.css';

function ContactDetails({ contactDetails, isEditMode, onCancel }) {
    const [formData, setFormData] = useState({
        mobile: '',
        work: '',
        home: '',
        workEmail: '',
        otherEmail: '',
        address: {
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: '',
            country: ''
        },
        permanentAddress: {
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: '',
            country: ''
        },
        isPermanentAddressSame: false,
    });

    // Update formData when contactDetails prop changes
    useEffect(() => {
        if (contactDetails) {
            const parsedAddress = parseAddress(contactDetails.Address);
            setFormData({
                mobile: contactDetails.Phone || '',
                work: contactDetails.OfficalPhone || '',
                home: contactDetails.EmergencyPhone || '',
                workEmail: contactDetails.OfficeEmail || '',
                otherEmail: contactDetails.Email || '',
                address: parsedAddress,
                permanentAddress: parsedAddress, // Initialize permanent address with the parsed address
                isPermanentAddressSame: contactDetails.isPermanentAddressSame || false,
            });
        }
    }, [contactDetails]);

    // Function to parse the address string into components
    const parseAddress = (address) => {
        const parts = address.split(',').map(part => part.trim());
    
        return {
            street1: parts[0] || '',
            street2: parts[1] || '',
            city: parts[2] || '',
            zip: parts[3] || '',
            state: parts[4] || '',
            country: parts[5] || ''
        };
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setFormData((prevData) => {
                const newData = {
                    ...prevData,
                    [name]: checked
                };

                // If the checkbox is checked, copy address to permanent address
                if (checked) {
                    newData.permanentAddress = { ...prevData.address };
                } else {
                    // Clear permanent address fields if unchecked
                    newData.permanentAddress = {
                        street1: '',
                        street2: '',
                        city: '',
                        state: '',
                        zip: '',
                        country: ''
                    };
                }
                return newData;
            });
        } else if (name.startsWith('address.')) {
            const addressKey = name.split('.')[1]; // Get the key after 'address.'
            setFormData((prevData) => {
                const updatedAddress = {
                    ...prevData.address,
                    [addressKey]: value
                };

                // Fetch location data if ZIP code changes
                if (addressKey === 'zip') {
                   
                }

                return {
                    ...prevData,
                    address: updatedAddress
                };
            });
        } else if (name.startsWith('permanentAddress.')) {
            const permanentAddressKey = name.split('.')[1]; // Get the key after 'permanentAddress.'
            setFormData((prevData) => ({
                ...prevData,
                permanentAddress: {
                    ...prevData.permanentAddress,
                    [permanentAddressKey]: value
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    // Function to fetch location data based on ZIP code


    // Handle cancel action
    const handleCancel = () => {
        if (onCancel) {
            onCancel(); // Call the onCancel prop function to reset state
        }
    };

    // Function to save updated contact details
    const handleSave = async () => {
        const addressString = [
            formData.address.street1,
            formData.address.street2,
            formData.address.city,
            formData.address.zip,
            formData.address.state,
            formData.address.country
        ].join(', ');

        const updatedContactDetails = {
            Phone: formData.mobile,
            OfficalPhone: formData.work,
            EmergencyPhone: formData.home,
            OfficeEmail: formData.workEmail,
            Email: formData.otherEmail,
            Address: addressString,
            isPermanentAddressSame: formData.isPermanentAddressSame,
            // You may also want to send the permanent address if needed
        };

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}edit-user-profile/${contactDetails._id}`, updatedContactDetails);
            console.log('User profile updated:', response.data);
            // Optionally call onCancel or some other success function
        } catch (error) {
            console.error('Error updating user details:', error);
        }
    };

    // Render loading state if contactDetails is not available
    if (!contactDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="contact-details">
            <div className="phone-section">
                <span>Contact Details</span>
                <hr />
                <div className="input-group">
                    <div className="input-container">
                        <label htmlFor="mobile" className="input-label">Mobile</label>
                        <input
                            type="text"
                            id="mobile"
                            name="mobile"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.mobile}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="work" className="input-label">Work</label>
                        <input
                            type="text"
                            id="work"
                            name="work"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.work}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="home" className="input-label">Home</label>
                        <input
                            type="text"
                            id="home"
                            name="home"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.home}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div className="email-section">
                <span>Email</span>
                <div className="input-group">
                    <div className="input-container">
                        <label htmlFor="work-email" className="input-label">Work Email</label>
                        <input
                            type="text"
                            id="work-email"
                            name="workEmail"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.workEmail}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="other-email" className="input-label">Other Email</label>
                        <input
                            type="text"
                            id="other-email"
                            name="otherEmail"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.otherEmail}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <hr />
                <span>Address</span>
                <div className="input-group">
                    <div className="input-container">
                        <label htmlFor="address-street1" className="input-label">Street 1</label>
                        <input
                            type='text'
                            id="address-street1"
                            name="address.street1"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.address.street1}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="address-street2" className="input-label">Street 2</label>
                        <input
                            type='text'
                            id="address-street2"
                            name="address.street2"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.address.street2}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="address-city" className="input-label">City</label>
                        <input
                            type='text'
                            id="address-city"
                            name="address.city"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.address.city}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="address-zip" className="input-label">Zip</label>
                        <input
                            type='text'
                            id="address-zip"
                            name="address.zip"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.address.zip}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="address-state" className="input-label">State</label>
                        <input
                            type='text'
                            id="address-state"
                            name="address.state"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.address.state}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="address-country" className="input-label">Country</label>
                        <input
                            type='text'
                            id="address-country"
                            name="address.country"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.address.country}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <hr />
                <span>Permanent Address</span>
                <div className="input-group">
                    <div className="input-container">
                        <label htmlFor="permanent-address-street1" className="input-label">Street 1</label>
                        <input
                            type='text'
                            id="permanent-address-street1"
                            name="permanentAddress.street1"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.permanentAddress.street1}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="permanent-address-street2" className="input-label">Street 2</label>
                        <input
                            type='text'
                            id="permanent-address-street2"
                            name="permanentAddress.street2"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.permanentAddress.street2}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="permanent-address-city" className="input-label">City</label>
                        <input
                            type='text'
                            id="permanent-address-city"
                            name="permanentAddress.city"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.permanentAddress.city}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="permanent-address-zip" className="input-label">Zip</label>
                        <input
                            type='text'
                            id="permanent-address-zip"
                            name="permanentAddress.zip"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.permanentAddress.zip}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="permanent-address-state" className="input-label">State</label>
                        <input
                            type='text'
                            id="permanent-address-state"
                            name="permanentAddress.state"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.permanentAddress.state}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-container">
                        <label htmlFor="permanent-address-country" className="input-label">Country</label>
                        <input
                            type='text'
                            id="permanent-address-country"
                            name="permanentAddress.country"
                            readOnly={!isEditMode}
                            className="input-field"
                            value={formData.permanentAddress.country}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="checkbox-container">
                    <label>
                        <input
                            type="checkbox"
                            name="isPermanentAddressSame"
                            checked={formData.isPermanentAddressSame}
                            onChange={handleChange}
                            disabled={!isEditMode}
                        />
                        Permanent Address same as Address
                    </label>
                </div>
                {isEditMode && (
                    <div className="button-container">
                        <button onClick={handleSave} className="save-button">Save</button>
                        <button onClick={handleCancel} className="cancel-button">Cancel</button>
                    </div>
                )}
            </div>
        </div>
    );
}

ContactDetails.propTypes = {
    contactDetails: PropTypes.object.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ContactDetails;
