import React, { useState, useEffect, useCallback } from 'react';
import './admin.css';
import { Link } from 'react-router-dom';

const Admin = () => {
  const [filters, setFilters] = useState({
    username: '',
    userRole: '',
    employeeName: '',
    status: ''
  });

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchUsers = useCallback(async () => {
    const queryParams = new URLSearchParams({
      ...filters,
      page: currentPage,
      limit: 10
    }).toString();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}users?${queryParams}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("jwt")
        }
      });
      const data = await response.json();

      if (response.ok) {
        setUsers(data.users || []);  // Ensure users is always an array
        setTotalPages(Math.ceil(data.total / 10));
      } else {
        alert('Error: ' + data.error);
      }
    } catch (err) {
      console.error('Error:', err);
      alert('An error occurred while fetching the users');
    }
  }, [filters, currentPage]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchUsers();
  };

  const handleReset = () => {
    setFilters({
      username: '',
      userRole: '',
      employeeName: '',
      status: ''
    });
    setCurrentPage(1);
    fetchUsers();
  };

  const handleDelete = async (userId) => {
    const confirmDelete = window.confirm("Do you want to delete this user?");
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}users/${userId}`, {
        method: 'DELETE',
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("jwt")
        }
      });

      if (response.ok) {
        fetchUsers();
      } else {
        const data = await response.json();
        alert('Error: ' + data.error);
      }
    } catch (err) {
      console.error('Error:', err);
      alert('An error occurred while deleting the user');
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className='admin-wrap'>
      <div className="filter-section">
        <input 
          type="text"  
          placeholder="Username" 
          name="username" 
          value={filters.username} 
          onChange={handleInputChange} 
        />
        <select name="userRole" value={filters.userRole} onChange={handleInputChange}>
          <option value="">-- Select --</option>
          <option value="user">User</option>
          <option value="administrator">Administrator</option>
          {/* Add more roles as needed */}
        </select>
        <input 
          type="text" 
          placeholder="Employee Name" 
          name="employeeName" 
          value={filters.employeeName} 
          onChange={handleInputChange} 
        />
        <select name="status" value={filters.status} onChange={handleInputChange}>
          <option value="">-- Select --</option>
          <option value="Enabled">Enabled</option>
          <option value="Disabled">Disabled</option>
        </select>
        <button onClick={handleReset}>Reset</button>
        <button onClick={handleSearch}>Search</button>
      </div>

      <div className="user-table">
       
        <table>
          <thead>
            <tr>
              <th>Username</th>
              <th>User Role</th>
              <th>Employee Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(users) && users.length > 0 ? (
              users.map((user) => (
                <tr key={user._id}>
                  <td>{user.FirstName}</td>
                  <td>{user.Role}</td>
                  <td>{`${user.FirstName} ${user.LastName}`}</td>
                  <td>{user.status}</td>
                  <td>
                    <Link to={`/edit-user/${user._id}`}><button>Edit</button></Link>
                    <button onClick={() => handleDelete(user._id)}>Delete</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No users found</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? 'active' : ''}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Admin;
