import React, { useEffect, useState } from 'react';
import './employeepermissionlist.css'; // Import the CSS file

const EmployeePermissionList = ({ selectedUser, allUsers, onClose, onSave }) => {
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  
  useEffect(() => {
    const fetchColumns = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}columns`);
        if (!response.ok) {
          throw new Error("Failed to fetch columns");
        }
        const data = await response.json();
        setAvailableColumns(data.columns);
      } catch (error) {
        console.error("Error fetching columns:", error.message);
      }
    };

    fetchColumns();

    if (selectedUser) {
      setSelectedEmployees([
        selectedUser._id.toString(),
        ...(selectedUser.PermissiontoSee || []).map((empId) => empId.toString()),
      ]);
      setSelectedColumns(selectedUser.AllowedColumns || []);
    }
  }, [selectedUser]);

  const handleEmployeeCheckboxChange = (employeeId) => {
    if (employeeId === selectedUser._id) {
      return;
    }

    setSelectedEmployees((prevSelectedEmployees) => {
      if (prevSelectedEmployees.includes(employeeId.toString())) {
        return prevSelectedEmployees.filter((id) => id !== employeeId.toString());
      } else {
        return [...prevSelectedEmployees, employeeId.toString()];
      }
    });
  };

  const handleColumnCheckboxChange = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      if (prevSelectedColumns.includes(column)) {
        return prevSelectedColumns.filter((col) => col !== column);
      } else {
        return [...prevSelectedColumns, column];
      }
    });
  };

  const handleSelectAllColumns = () => {
    setSelectedColumns(availableColumns);
  };

  const handleClearAllColumns = () => {
    setSelectedColumns([]);
  };

  const handleSavePermissions = async () => {
    if (!selectedUser) {
      alert("No user selected or no employees selected");
      return;
    }

    const uniqueEmployees = [...new Set(selectedEmployees)];
    const uniqueColumns = [...new Set(selectedColumns)];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}update-permissions/${selectedUser._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          body: JSON.stringify({
            selectedEmployees: uniqueEmployees,
            selectedColumns: uniqueColumns,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update permissions");
      }

      const result = await response.json();
      console.log("Permissions updated successfully:", result);

      if (onSave) {
        onSave();
      }
    } catch (error) {
      console.error("Error saving permissions:", error.message);
    }
  };

  const isSaveDisabled = !selectedUser || selectedEmployees.length === 0;

  if (!selectedUser) {
    return (
      <div className="permissions-container">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="permissions-container">
      <h2 className="instruction-title1">
        Assign permissions to <strong>{selectedUser?.FirstName}</strong>
      </h2>

      <div className="permissions-sections">
        <div className="permission-section">
          <h3 className="instruction-title2">
            Select Employees to Grant Permissions
          </h3>
          {allUsers && allUsers.length > 0 ? (
            <ul className="user-list">
              {allUsers.map((employee) => {
                const isChecked = selectedEmployees.includes(employee._id.toString());
                const isDisabled = employee._id === selectedUser._id;

                return (
                  <li className="employee-list-item" key={employee._id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        disabled={isDisabled}
                        onChange={() => handleEmployeeCheckboxChange(employee._id)}
                      />
                      {employee.FirstName} {employee.LastName}
                    </label>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>No employees found.</p>
          )}
        </div>

        <div className="permission-section">
          <h3 className="instruction-title2">Select Columns to Display</h3>
          {availableColumns.length > 0 ? (
            <div>
              <button className="select-all-btn" onClick={handleSelectAllColumns}>
                Select All
              </button>
              <button className="clear-all-btn" onClick={handleClearAllColumns}>
                Clear All
              </button>
              <ul className="columns-list">
                {availableColumns.map((column) => {
                  const isChecked = selectedColumns.includes(column);
                  return (
                    <li key={column}>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={() => handleColumnCheckboxChange(column)}
                        />
                        {column}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <p>No columns available.</p>
          )}
        </div>
      </div>

      <div className="buttons-container">
        <button
          className="save-permission-btn"
          onClick={handleSavePermissions}
          disabled={isSaveDisabled}
        >
          Save Permissions
        </button>
        <button className="cancel-edit" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EmployeePermissionList;
