import React, { useState } from 'react';

const FetchAttendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch attendance data
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}fetch-essl-data`); // Adjust the path if needed
      const data = await response.json();

      if (response.ok) {
        setAttendanceData(data);
      } else {
        setError(data.message || 'Failed to fetch attendance data');
      }
    } catch (err) {
      setError('Error fetching data from server');
    }
    setLoading(false);
  };

  return (
    <div>
      <h2>Attendance Records</h2>
      <button onClick={fetchData} disabled={loading}>
        {loading ? 'Fetching...' : 'Fetch Attendance Data'}
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {attendanceData.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>AC No</th>
              <th>Name</th>
              <th>Date</th>
              <th>On Duty</th>
              <th>Off Duty</th>
              <th>Clock In</th>
              <th>Clock Out</th>
              <th>Late</th>
              <th>Early</th>
              <th>Work Time</th>
              <th>Department</th>
            </tr>
          </thead>
          <tbody>
            {attendanceData.map((record, index) => (
              <tr key={index}>
                <td>{record.acNo}</td>
                <td>{record.name}</td>
                <td>{record.date}</td>
                <td>{record.onDuty}</td>
                <td>{record.offDuty}</td>
                <td>{record.clockIn}</td>
                <td>{record.clockOut}</td>
                <td>{record.late}</td>
                <td>{record.early}</td>
                <td>{record.workTime}</td>
                <td>{record.department}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FetchAttendance;
