import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./dashboard.css";
import Reports from "../../component/Reports/Reports";
import Avatar from "@mui/material/Avatar";
import { Link, useLocation } from "react-router-dom";

function DashBoard() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedUserId = searchParams.get("userId");
  const userObject = JSON.parse(localStorage.getItem("user") || "{}");
  const loggedInUserId = userObject._id || "";

  const fetchNotifications = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}notifications`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setNotifications(data.notifications);
    } catch (error) {
      console.error("Fetch error:", error);
      toast.error("Failed to fetch notifications", {
        autoClose: 5000,
        position: "top-right",
        style: {
          background: "linear-gradient(to right, #ff5f6d, #ffc371)",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}projectusers`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setUsers(data.allUsers);

      const loggedInUser = data.allUsers.find(
        (user) => user._id === loggedInUserId
      );

      if (loggedInUser && loggedInUser.PermissiontoSee) {
        const usersToShow = data.allUsers.filter((user) =>
          loggedInUser.PermissiontoSee.includes(user._id)
        );
        setFilteredUsers(usersToShow);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      toast.error("Failed to fetch users", {
        autoClose: 5000,
        position: "top-right",
        style: {
          background: "linear-gradient(to right, #ff5f6d, #ffc371)",
        },
      });
    }
  }, [loggedInUserId]);

  useEffect(() => {
    fetchNotifications();
    fetchUsers();
  }, [fetchUsers]);

  const shouldShowReports =
    filteredUsers.length === 1 && filteredUsers[0]._id === loggedInUserId;

  return (
    <div className="dashboard-wrap">
      <ToastContainer />
      <div className="dashboard-grid">
        <div className="db-grid-item">My Actions</div>
        <div className="db-grid-item">Quick Launch</div>
        <div className="db-grid-item notification-wrap">
          <div className="notifications">
            {loading ? (
              <div>Loading notifications...</div>
            ) : notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <div
                  key={index}
                  className={`notification ${notification.type
                    .toLowerCase()
                    .replace(" ", "-")}`}
                >
                  {notification.message}
                </div>
              ))
            ) : (
              <div>No notifications for today</div>
            )}
          </div>
        </div>
        <div className="db-grid-item">Employees on Leave Today</div>
      </div>

      {/* Conditionally render Reports or Profile Cards */}
      {selectedUserId ? (
        <Reports
          userId={selectedUserId}
          userName={
            users.find((user) => user._id === selectedUserId)?.FirstName +
            " " +
            users.find((user) => user._id === selectedUserId)?.LastName
          }
        />
      ) : shouldShowReports ? (
        <Reports
          userId={loggedInUserId}
          userName={userObject.FirstName + " " + userObject.LastName}
        />
      ) : (
        <div className="user-profiles">
          {filteredUsers.map((user) => (
            <Link
              key={user._id}
              to={`?userId=${user._id}`}
              className="profile-card"
            >
              <Avatar
                src={`path_to_image/${user._id}`} // Update with actual image path
                alt={`${user.FirstName} ${user.LastName}`}
              />
              <div className="profile-info">
                <h3>
                  {user.FirstName} {user.LastName}
                </h3>
                <p>{user.Designation}</p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default DashBoard;
